import React, { useState } from 'react'
import { Column, Grid } from 'components/grid'
import Form from 'react-netlify-form'
import formDataEntries from 'form-data-entries'
import { Submit } from 'components/forms/elements/v2/submit'
import Spacer from 'components/new/spacer'
import Email from 'components/forms/elements/email'
import Phone from 'components/forms/elements/phone'
import Input from 'components/forms/elements/input'
import Location from 'components/forms/elements/location'
import Number from 'components/forms/elements/number'
import Select from 'components/forms/elements/select'
import Textarea from 'components/forms/elements/textarea'
import RadioInput from 'components/forms/elements/radio-input'
import ResponseMessage from 'components/forms/elements/response-message'
import { useEffectOnce } from 'hooks/use-effect-once'
import { useFormValidation } from 'hooks/use-form-validation'

let isSubscribed = false

const GreenstarUpdateRequestForm = () => {
  const [valid, updateValidationSet] = useFormValidation({})
  const [started, setStarted] = useState(false)
  const [customerOrEmployee, setCustomerOrEmployee] = useState('Customer')
  const [responseData, setResponseData] = useState({
    error: false,
    message: '',
    messageBody: '',
    success: false,
  })

  const handleChangeCustomerOrEmployee = e => {
    setCustomerOrEmployee(e.target.value)
    if (e.target.value === 'Customer') {
      updateValidationSet('Employee Name', true)
    }
  }

  useEffectOnce(() => {
    isSubscribed = true
    return () => {
      isSubscribed = false
    }
  })

  const onSubmitForm = () => {
    const formData = {}
    for (const [key, value] of formDataEntries(document.forms['Greenstar Update Request'])) {
      formData[key] = value
    }
    formData['pageName'] = document.title

    fetch('/api/greenstar-update-request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(formData),
    })
      .then(res => {
        if (isSubscribed) {
          const ok = res.ok

          setResponseData({
            error: !ok,
            message: ok ? 'Success!' : 'Error.',
            messageBody: ok
              ? 'Your form has been submitted.'
              : 'There was an issue submitting your form. If problems persist please contact <a href="mailto:contactus@hutsoninc.com">our support team</a>.',
            success: ok,
          })
        }
      })
      .catch(err => {
        if (isSubscribed) {
          console.error('Error submitting form: ', err)
          setResponseData({
            error: true,
            message: 'Error.',
            messageBody:
              'There was an issue submitting your form. If problems persist please contact <a href="mailto:contactus@hutsoninc.com">our support team</a>.',
            success: false,
          })
        }
      })
  }
  return (
    <Form name='Greenstar Update Request' onSubmit={onSubmitForm}>
      {({ loading }) => (
        <div>
          {responseData.error && (
            <ResponseMessage
              error={responseData.error}
              message={responseData.message}
              messageBody={responseData.messageBody}
            />
          )}
          {responseData.success && (
            <ResponseMessage
              message={responseData.message}
              messageBody={responseData.messageBody}
            />
          )}
          {!responseData.success && (
            <>
              <div onFocus={() => setStarted(true)} role='none'>
                <RadioInput
                  label='Are you filling this out as a customer or a Hutson employee?'
                  name='Customer or employee'
                  required
                  initialValue='Customer'
                  onChange={handleChangeCustomerOrEmployee}
                  options={[
                    {
                      label: 'Customer',
                      id: 'customer',
                    },
                    {
                      label: 'Employee',
                      id: 'employee',
                    },
                  ]}
                  updateValidationSet={updateValidationSet}
                />
                {customerOrEmployee === 'Employee' && (
                  <Grid>
                    <Column columns='1/2'>
                      <Input
                        updateValidationSet={updateValidationSet}
                        label='Employee Name'
                        name='Employee Name'
                        validationError='Please enter your name.'
                        required
                      />
                    </Column>
                    <Column columns='1/2'>
                      <Input
                        updateValidationSet={updateValidationSet}
                        label='Customer Equip Account Number'
                        name='Customer Account Number'
                        validationError="Please enter the customer's Equip account number."
                        required
                      />
                    </Column>
                  </Grid>
                )}
                <Grid>
                  <Column columns='1/2'>
                    <Input
                      updateValidationSet={updateValidationSet}
                      label='Customer Name'
                      name='Customer Name'
                      validationError='Please enter your name.'
                      required
                    />
                  </Column>
                  <Column columns='1/2'>
                    <Input
                      updateValidationSet={updateValidationSet}
                      label='Farm Name'
                      name='Farm Name'
                      validationError='Please enter your farm name.'
                      required
                    />
                  </Column>
                </Grid>
                <Grid>
                  <Column columns='2/3'>
                    <Email updateValidationSet={updateValidationSet} required />
                  </Column>
                  <Column columns='1/3'>
                    <Phone updateValidationSet={updateValidationSet} required />
                  </Column>
                </Grid>
                <Grid>
                  <Column columns='1/2'>
                    <Location updateValidationSet={updateValidationSet} required />
                  </Column>
                  <Column columns='1/2'>
                    <Select
                      updateValidationSet={updateValidationSet}
                      label='Drop off or on farm?'
                      name='Drop off or on farm'
                      required
                    >
                      <option value='' disabled>
                        Select an option
                      </option>
                      <option value='Drop Off'>Drop Off</option>
                      <option value='On Farm'>On Farm</option>
                    </Select>
                  </Column>
                </Grid>
                <Grid>
                  <Column columns='1/3'>
                    <Number
                      updateValidationSet={updateValidationSet}
                      label='Number of receivers'
                      name='Number of receivers'
                      required
                    />
                  </Column>
                  <Column columns='1/3'>
                    <Number
                      updateValidationSet={updateValidationSet}
                      label='Number of displays'
                      name='Number of displays'
                      required
                    />
                  </Column>
                  <Column columns='1/3'>
                    <Select
                      updateValidationSet={updateValidationSet}
                      label='Method of payment'
                      name='Method of payment'
                      required
                    >
                      <option value='' disabled>
                        Select an option
                      </option>
                      <option value='Cash'>Cash</option>
                      <option value='Credit Card'>Credit Card</option>
                      <option value='Farm Plan'>Farm Plan</option>
                    </Select>
                  </Column>
                </Grid>
                <p
                  style={{
                    fontSize: '1.2em',
                    fontWeight: '600',
                    color: '#2a2a2a',
                    margin: '20px 0 0',
                  }}
                >
                  Free Operations Center Opportunity
                </p>
                <RadioInput
                  label='Would you like to view and analyze your planting and harvest data on your personal computer or smartphone? If so, we can create an Operations Center account for you and upload your display data for free.'
                  name='Analyze data'
                  required
                  options={[
                    {
                      label: 'Yes',
                      id: 'yes',
                    },
                    {
                      label: 'No',
                      id: 'no',
                    },
                    {
                      label: 'Already using',
                      id: 'Already using',
                    },
                  ]}
                  updateValidationSet={updateValidationSet}
                />
                <Textarea
                  updateValidationSet={updateValidationSet}
                  label='Any other notes or questions?'
                  name='Message'
                  validationError='Please enter a valid message.'
                />
                <Spacer size='m' />
                <Submit disabled={!valid && started} loading={loading} />
              </div>
            </>
          )}
        </div>
      )}
    </Form>
  )
}

export default GreenstarUpdateRequestForm
