import React, { useCallback, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import noop from 'utils/noop'

const RadioInput = ({
  className,
  initialValue,
  label,
  name,
  onChange,
  options,
  required,
  updateValidationSet,
  validationError,
  ...props
}) => {
  const [displayingError, setDisplayingError] = useState(false)
  const [error, setError] = useState(false)
  const [value, setValue] = useState(initialValue)

  required = required ? 'required' : ''

  const validate = useCallback(() => {
    let err = false
    let isValid = true

    if (!value && required) {
      err = 'This field is required.'
      isValid = false
    }

    setError(err)
    updateValidationSet(name, isValid)
  }, [value, required, name, updateValidationSet])

  useEffect(() => {
    validate()
  }, [value, required, validate])

  const handleChange = e => {
    setValue(e.target.value)
    setError(false)
    setDisplayingError(false)
    onChange(e)
  }

  const handleBlur = () => {
    setDisplayingError(!!error)
  }
  return (
    <div className={`label ${displayingError ? 'error' : ''} ${required} ${className}`} {...props}>
      <span className='labelText'>{label}</span>
      <OptionsList>
        {options.map(({ label: optLabel, id: optId, ...opt }) => (
          <li key={optId}>
            <Label htmlFor={optId}>
              <input
                type='radio'
                id={optId}
                name={name}
                value={optLabel}
                onChange={handleChange}
                onBlur={handleBlur}
                required={required}
                checked={value === optLabel}
                {...opt}
              />
              {optLabel}
            </Label>
          </li>
        ))}
      </OptionsList>
      {displayingError && <div className='msg'>{error}</div>}
    </div>
  )
}

RadioInput.propTypes = {
  className: PropTypes.string,
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  required: PropTypes.bool,
  updateValidationSet: PropTypes.func,
  validationError: PropTypes.string,
}

RadioInput.defaultProps = {
  className: '',
  initialValue: '',
  onChange: noop,
  required: false,
  updateValidationSet: noop,
  validationError: '',
}

const OptionsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0;
    padding: 0;

    :not(:last-of-type) {
      margin-right: 10px;
    }
  }
`

const Label = styled.label`
  display: inline-block;
`

export default RadioInput
