import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const H4 = ({ children, ...props }) => {
  return <StyledH4 {...props}>{children}</StyledH4>
}

H4.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledH4 = styled.h3`
  font-size: 1.05em;
  font-weight: 600;
  margin: 0;
  text-transform: none;

  @media (min-width: 800px) {
    font-size: 1.15em;
  }
`

export default H4
