import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Form from 'components/forms/greenstar-update-request'
import { Helmet } from 'react-helmet'
import HeroImage from 'components/new/hero/hero-image-title'
import Layout from 'components/layout'
import Content from 'components/content'
import Section from 'components/section'
import H3 from 'components/typography/h3'
import H4 from 'components/typography/h4'

const GreenstarUpdatesPage = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Greenstar Update Request | Hutson Inc</title>
      </Helmet>

      <Hero
        title='Greenstar Update Request'
        image={heroImage.childImageSharp.gatsbyImageData}
        contentProps={{ kind: 'default', padding: false, className: 'hero-content' }}
      />

      <Content>
        <h2>StarFire receivers must be updated by January 2021</h2>
        <p>
          Beginning January 2021, StarFire receivers will no longer receive the signals required to
          utilize AutoTrac, Section Control and other precision ag functions. All StarFire 3000 and
          StarFire 6000 receivers must have the 20-2 update to continue working.
        </p>
        <H3>What’s included in this update?</H3>
        <p>
          This update includes software fixes plus a few new features. The most important part of
          the 20-2 software update is that it positions the current receivers to continue operating
          into the future. Changes coming with the 20-2 software update will prepare the StarFire
          network to support new satellite constellations and the next generation StarFire receiver.
        </p>
        <H3>Update fees</H3>
        <div>
          <FeeContainer>
            <FeeTitle>Drop Off</FeeTitle>
            <Fee>
              $50<FeeSuffix>/device</FeeSuffix>
            </Fee>
          </FeeContainer>
          <FeeContainer>
            <FeeTitle>On Farm</FeeTitle>
            <Fee>
              $75<FeeSuffix>/device</FeeSuffix>
            </Fee>
          </FeeContainer>
        </div>
        <Section>
          <h2>Greenstar Update Request Form</h2>
          <Form />
        </Section>
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImage)`
  height: 300px;

  .hero-content {
    padding-bottom: ${props => props.theme.size.l};
  }

  @media ${props => props.theme.mediaQuery.largeUp} {
    height: 450px;

    .hero-content {
      padding-bottom: ${props => props.theme.size.xl};
    }
  }
`

const FeeContainer = styled.div`
  background-color: ${props => props.theme.color.n20};
  margin: 1em 0 0;
  max-width: 175px;
  padding: ${props => props.theme.size.s};

  @media (min-width: 480px) {
    display: inline-block;
    max-width: auto;
    margin: 1em 30px 0 0;
  }
`

const FeeTitle = styled(H4)`
  font-weight: 600;
  margin: 0 0 ${props => props.theme.size.xxs};
  padding: 0;
`

const Fee = styled.span`
  font-size: 2.5em;
  font-weight: bold;
`

const FeeSuffix = styled.span`
  font-size: 0.5em;
  font-weight: 400;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "greenstar-hero.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default GreenstarUpdatesPage
